<template>
  <div class="edit-item-container">
    <div class="item" v-bind:class="[item.i_type]" v-on:click="toggleEditImage">
      <span class="edit">{{ editImageText }}</span>
    </div>
    <ItypeSelector v-if="editImage" :selectItypeHandler="selectItype" :img_data="items_data"/>
    <div class="form-fields inline">
      <label for="itemName">Nom</label>
      <input v-model="item.name" id="itemName" name="name" type="text"/>

    </div>
    <div class="form-fields inline">
      <label for="weight">Poid</label>
      <input v-model.number="item.weight" id="weight" name="weight" type="number" min="0"/>
    </div>
    <div class="form-fields">
      <label for="description">Description</label>
      <textarea v-model="item.description" id="description" name="description"/>
    </div>
    <div class="form-fields inline" v-if="item.speed_factor !== undefined">
      <label for="speed_factor">Facteur de vitesse</label>
      <input v-model.number="item.speed_factor" id="speed_factor" name="speed_factor" type="number"/>
    </div>
    <div class="form-fields inline magical-form">
      <label for="magical">Magique</label>
      <input v-model="item.magical" id="magical" name="magical" type="checkbox"/>
    </div>
    <div v-if="item.magical" class="form-fields magical-form">
      <label  for="magic">Magie</label>
      <textarea v-model="item.magic" id="magic" name="magic"/>
    </div>
    <div class="form-fields inline dammage-form" v-if="item.damage !== undefined">
      <label>Dommage</label>
      <label for="damage_small">Sm</label>
      <input v-model="item.damage.small" id="damage_small" name="damage_small" type="text"/>
      <label for="damage_large">L</label>
      <input v-model="item.damage.large" id="damage_large" name="damage_large" type="text"/>
    </div>
    <div class="form-fields inline" v-if="item.armor_class !== undefined">
      <label for="armor_class">Classe d'armure</label>
      <input v-model.number="item.armor_class" id="armor_class" name="armor_class" type="number"/>
    </div>
    <div class="form-fields inline" v-if="item.equipable !== undefined">
      <label for="equipable">Peut etre equipé</label>
      <input v-if="item.equipable !== undefined" v-model="item.equipable" id="equipable" name="equipable"
             type="checkbox"/>
      <div v-if="item.equipable">
        <select v-model="item.body_part">
          <option value="">-</option>
          <option value="Head">Tete</option>
          <option value="OneHand">Une Main</option>
          <option value="TwoHands">Deux Mains</option>
          <option value="Legs">Jambes</option>
          <option value="Foot">Pieds</option>
        </select>
      </div>
    </div>
    <div v-if="allowQuantity" class="form-fields inline">
      <label for="quantity">Quantité</label>
      <input v-model.number="item.quantity" id="quantity" name="quantity" type="number"/>
    </div>
    <div class="form-fields inline">
      <div>Valeur</div>
      <label for="unknown_value">Inconnue</label>
      <input v-model="item.value.unknown" id="unknown_value" name="unknown_value" type="checkbox"/>
      <div class="value_currency_form" v-if="!item.value.unknown">
        <label for="value_platinum" class="currency platinum"></label>
        <input v-model.number="item.value.currency.platinum" id="value_platinum" type="number"/>
        <label for="value_gold" class="currency gold"></label>
        <input v-model.number="item.value.currency.gold" id="value_gold" type="number"/>
        <label for="value_silver" class="currency silver"></label>
        <input v-model.number="item.value.currency.silver" id="value_silver" type="number"/>
        <label for="value_copper" class="currency copper"></label>
        <input v-model.number="item.value.currency.copper" id="value_copper" type="number"/>
      </div>
    </div>
    <button value="done" v-on:click="done">Done</button>
  </div>
</template>

<script>
import ItypeSelector from "@/components/ItypeSelector";

const items = require('../data/items.json');
export default {
  name: "EditItem",
  data() {
    return {
      item: this.item_selected,
      editImage: false,
      editImageText: "Click pour changer",
      items_data: items
    }
  },
  props: {
    item_selected: {},
    done_handler: {type: Function},
    allowQuantity: Boolean
  },
  components: {
    ItypeSelector
  },
  methods: {
    toggleEditImage() {
      this.editImage = !this.editImage;
      if (this.editImage) {
        this.editImageText = "Click pour annuler"
      } else {
        this.editImageText = "Click pour changer"
      }

    },
    selectItype(itype) {
      this.item.i_type = itype
      this.editImage = false
    },
    done() {
      this.done_handler(this.item)
    }
  }
}
</script>

<style>
.dammage-form input {
  max-width: 50px;
}

.item .edit {
  font-size: 14px;
  margin: 50px auto;
}
</style>