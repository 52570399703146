<template>
  <section id="summary-container">
    <h2 v-show="!showField('name')" @click="focusField('name')" class="center"> {{ attributes.name }}</h2>
    <input v-model="attributes.name" v-show="showField('name')" id="character.name" type="text"
           @focus="focusField('name')" @blur="blurField" >
    <div class="avatar" @click="editAvatar" v-bind:class="[attributes.avatar]">
        <span v-if="attributes.avatar ==='' ">Selectioner un avatar</span>
    </div>
    <div class="center"> {{ character_class }} Niveau {{ character.level() }}</div>
    <div class="center">
      <span v-show="!showField('xp')" @click="focusField('xp')">{{ attributes.xp }}</span>
      <input  v-model="attributes.xp" v-show="showField('xp')" id="character.xp" type="number"
             @focus="focusField('xp')" @blur="blurField('xp',$event.target.value)">
      <ItypeSelector v-if="edit_avatar" :img_data="characters_data" :select-itype-handler="selectItype"/>
      XP
    </div>
    <div v-show="!showField('health')" class="health" @click="focusField('health')">
      <div class="light-grey">
        <div  class="green center" v-bind:class="healthClass" v-bind:style="{width:health +'%'}">&nbsp;</div>
        <div class="health-label">{{ attributes.hp }}/{{ attributes.max_hp }}</div>
      </div>
    </div>
    <label v-show="showField('health')" for="character.health">hp:</label>
    <input class="attribute-number" v-model="attributes.hp" v-show="showField('health')" id="character.health" type="number"
           @focus="focusField('health')" @blur="blurField" >
    <label v-show="showField('health')" for="character.max_hp">max hp:</label>
    <input class="attribute-number" v-model="attributes.max_hp" v-show="showField('health')" id="character.max_hp" type="number"
           @focus="focusField('health')" @blur="blurField" >
    <div id="attributes">
      <ul>
        <li>
          <label for="character.strength">Force:</label>
          <span v-show="!showField('strength')" @click="focusField('strength')">{{ attributes.strength }}</span>
          <input class="attribute-number" v-model="attributes.strength" v-show="showField('strength')" id="character.strength" type="number"
                 @focus="focusField('strength')" @blur="blurField" max="20" min="1">
        </li>
        <li>
          <label for="character.dexterity">Dexterité:</label>
          <span v-show="!showField('dexterity')" @click="focusField('dexterity')">{{ attributes.dexterity }}</span>
          <input class="attribute-number" v-model="attributes.dexterity" v-show="showField('dexterity')" id="character.dexterity" type="number"
                 @focus="focusField('dexterity')" @blur="blurField" max="20" min="1">

        </li>
        <li>
          <label for="character.constitution">Constitution:</label>
          <span v-show="!showField('constitution')" @click="focusField('constitution')">{{ attributes.constitution }}</span>
          <input class="attribute-number" v-model="attributes.constitution" v-show="showField('constitution')" id="character.constitution" type="number"
                 @focus="focusField('constitution')" @blur="blurField" max="20" min="1">
        </li>
        <li>
          <label for="character.intelligence">Intelligence:</label>
          <span v-show="!showField('intelligence')" @click="focusField('intelligence')">{{ attributes.intelligence }}</span>
          <input class="attribute-number" v-model="attributes.intelligence" v-show="showField('intelligence')" id="character.intelligence" type="number"
                 @focus="focusField('intelligence')" @blur="blurField" max="20" min="1">
        </li>
        <li>
          <label for="character.wisdom">Sagesse:</label>
          <span v-show="!showField('wisdom')" @click="focusField('wisdom')">{{ attributes.wisdom }}</span>
          <input class="attribute-number" v-model="attributes.wisdom" v-show="showField('wisdom')" id="character.wisdom" type="number"
                 @focus="focusField('wisdom')" @blur="blurField" max="20" min="1">
        </li>
        <li><label for="character.charisma">Charisme:</label>
          <span v-show="!showField('charisma')" @click="focusField('charisma')">{{ attributes.charisma }}</span>
          <input class="attribute-number" v-model="attributes.charisma" v-show="showField('charisma')" id="character.charisma" type="number"
                 @focus="focusField('charisma')" @blur="blurField" max="20" min="1"></li>
      </ul>
    </div>
  </section>
</template>
<script>

import {classMapFr, raceFr} from "@/const";
import ItypeSelector from "@/components/ItypeSelector";

const characters = require('../data/characters.json');
export default {
  name: 'c-summary',
  data() {
    return {
      characters_data: characters,
      editField: '',
      edit_avatar:false,
      attributes: {
        name:this.character.name(),
        hp:this.character.hp,
        max_hp:this.character.max_hp,
        xp:this.character.xp,
        strength:this.character.strength,
        dexterity: this.character.dexterity,
        constitution: this.character.constitution,
        intelligence: this.character.intelligence,
        wisdom: this.character.wisdom,
        charisma: this.character.charisma,
        avatar: this.character.avatar()
      }
    }
  },
  components: {ItypeSelector},
  props: {
    character: {},
    update_attributes_handler: {},
  },
  methods: {
    focusField(name) {
      this.editField = name;
    },
    blurField(name, value) {
      this.update_attributes_handler(this.attributes)
      this.editField = '';
    },
    showField(name) {
      return this.editField === name
    },
    editAvatar(){
      this.edit_avatar = !this.edit_avatar
    },
    selectItype(itype) {
      this.attributes.avatar = itype
      this.update_attributes_handler(this.attributes)
    }
  },
  computed: {
    character_class() {
      return classMapFr[this.character.class]
    },
    character_race() {
      return raceFr[this.character.race]
    },
    health() {
      return this.attributes.hp / this.attributes.max_hp * 100
    },
    healthClass(){
      const health = this.health
      if (health>50){
        return "good"
      }else if(health>25){
        return "medium"
      }else{
        return "bad"
      }
    }
  },
  watch: {
    character: function (newVal) {
      this.attributes= {
        hp:newVal.hp,
            max_hp:newVal.max_hp,
            xp:newVal.xp,
            strength:newVal.strength,
            dexterity: newVal.dexterity,
            constitution: newVal.constitution,
            intelligence: newVal.intelligence,
            wisdom: newVal.wisdom,
            charisma: newVal.charisma,
            avatar: newVal.avatar()
      }
    }
  }
}
</script>

<style>
.avatar{
  width: 128px;
  height: 128px;
  background-size: 100%;
  margin: 0 auto
}

.avatar span{
  display: inline-block;
  margin-top: 30px;
  text-align: center;
}
#summary-container {
  max-width: 200px;
  min-width: 140px;
}

#summary-container h2, #summary-container h3 {
  margin: 0.2em;
}

.health {
  margin: 0.5em;
  font-size: small;
  position: relative;
}

.health-label{
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  color: black;
  font-weight: bold;
}

.light-grey {
  background-color: lightgray;
}

.good {
  background-color: green;
}

.medium {
  background-color: #ff9900;
}

.bad {
  background-color: red;
}

.attribute-number{
  width: 2.5em;
}

#attributes ul{
  margin-bottom: 0;
}
</style>