<template>
  <div v-if="!edit" v-on:click="editMoney">
    <span class="currency platinum"></span>{{ currency.platinum }}
    <span class="currency gold"></span>{{ currency.gold }}
    <span class="currency silver"></span>{{ currency.silver }}
    <span class="currency copper"></span>{{ currency.copper }}
  </div>
  <div v-if="edit">
    <EditMoney  :currency="currency"/>
    <button v-on:click="endEdit">Done</button>
  </div>
</template>
<script>
import EditMoney from "@/components/EditMoney";
export default {
  name: 'money',
  components: {EditMoney},
  data(){
    return{
      edit:false,
    }
  },
  props: {
    currency: {},
    money_change_handler: {type: Function},
  },
  methods:{
    editMoney:function (){
      if (this.money_change_handler){
        this.edit = true
      }
    },
    endEdit:function (){
      this.edit = false
      this.money_change_handler(this.currency)
    }
  }
}
</script>
<style>
.currency {
  /*background-image: url(../assets/img/items32.png);*/
  /*background-repeat: no-repeat;*/
  /*background-position: -192px -384px;*/
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 100%;
  vertical-align: text-bottom;
}

.platinum {
  background-image: url(../assets/img/icons/platinum.png);
}

.copper {
  background-image: url(../assets/img/icons/copper.png);
}

.silver {
  background-image: url(../assets/img/icons/silver.png);
}

.gold {
  background-image: url(../assets/img/icons/gold.png);
}


</style>