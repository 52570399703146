<template>
  <ul class="items thumb">
    <li v-for="(item, index) in items" :key="item.name" v-on:click="select_item(index)" class="item-case item"
        :title="item.name"
        v-bind:class="{'selected':(index === selected_item), 'magical':(item.magical), [item.i_type]:true}"><span
        v-if="item.quantity >1">{{ item.quantity }}</span></li>
    <li class="item-case item empty" v-for="n in free_slots" :key="n"></li>
  </ul>
</template>

<script>

export default {
  name: 'Items',
  data: () => ({
    selected_item: Number,
  }),
  props: {
    items: {},
    free_slots: Number,
    click_handler: {},
    selected_index: Number,
  },
  methods: {
    select_item: function (index) {
      this.selected_item = index
      this.click_handler(index)
    }
  },
  watch: {
    selected_index: function (newVal) {
      this.selected_item = newVal
    }
  }
}
</script>

<style>



#market .items {
  max-height: none;
}

.items li.empty {
  background-color: #0202027c;
}

.items li.selected {
  background-color: #0202027c;
  border-color: brown;
}

</style>