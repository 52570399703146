<template>
  <div id="groupItems" >
    <ItemPicker title="Group Items"
                :pick_item_handler="pick_item_handler" :create_handler="add_item_handler"
                :update_item_handler="update_item_handler" :items_prop="items"/>
  </div>
</template>
<script>

import ItemPicker from "@/components/ItemPicker";

export default {
  name: 'GroupItems',
  data: () => ({

    items: {}
  }),
  components: {
    ItemPicker
  },
  props: {
    items_prop:{},
    pick_item_handler: {type: Function},
    add_item_handler: {type: Function},
    update_item_handler: {type: Function},
  },
  methods: {
    addNewItem: async function (item) {
      if(this.items === null){
        this.items = []
      }
      this.items.push(item)

    },
  },
  async mounted() {
    this.items = this.items_prop
  },
  watch:{
    items_prop:function(newVal){
      this.items = newVal
    }
  }
}
</script>


