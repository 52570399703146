<template>
  <div class="form-fields inline">
    <label for="name">Nom</label>
    <input v-model="name" id="name" name="name" type="text"/>
  </div>
  <div class="form-fields inline">
    <label for="type">Type</label>
    <select v-model="type" id="type">
      <option value="Item">Objet</option>
      <option value="Armor">Armure</option>
      <option value="Weapon">Arme</option>
      <option value="RangedWeapon">Arc et Arbaletes</option>
      <option value="Projectile">Fleches et munitions</option>
      <option value="Shield">Bouclier</option>
    </select>
    <button v-if="type && name" v-on:click="create">Ok</button>
  </div>

  <div v-if="item">
    <EditItem :item_prop="item"/>
  </div>

</template>
<script>
import EditItem from "@/components/EditItem";
import * as wasm from "csrpglib";

export default {
  name: 'NewItem',
  props: {
    new_item_handler: {},
  },
  components: {
    EditItem
  },
  data() {
    return {
      item: undefined,
      name: "",
      type: "",
    }
  },
  methods: {
    create: function () {
      let item = wasm.create_object(this.type, this.name)
      this.new_item_handler(item)
    }
  }
}
</script>