<template>
  <router-link to="/">Back</router-link>
  <Character-c v-if="characterId" ref="character" :character-id="characterId" :load-character="loadCharacter"
               :save-character="saveCharacter" :send_to_group_handler="addNewGroupItem" :group="true"/>

  <div class="section-container">
    <section class="select-menu">
      <ul>
        <li @click="selectCat('itemGroup')" v-bind:class="{'selected':showCat('itemGroup')}"><span
            class="select-group-item"></span></li>
        <li @click="selectCat('itemMarket')" v-bind:class="{'selected':showCat('itemMarket')}"><span
            class="select-market"></span></li>
      </ul>
    </section>

    <GroupItems v-show="showCat('itemGroup')" v-if="group.inventory" :items_prop="group.inventory.items"
                :add_item_handler="addNewGroupItem" :pick_item_handler="pickGroupItem"
                :update_item_handler="updateGroupItem"/>
    <Market v-show="showCat('itemMarket')"
            :pick_item_handler="pickItem"
            :buy_item_handler="buyItem"
    />
  </div>

  <section class="status-bar">
    <div v-for="member in members" :key="member.id" class="member"
         v-bind:class="{'selected':(member.id === characterId)}" @click="selectCharacter(member)">
      {{ member.name }}
      <div class="avatar" v-bind:class="[member.avatar]"></div>
    </div>
  </section>
  <div class="invisible-div">

  </div>
</template>
<script>
import Market from "../components/Market.vue";
import CharacterC from "../components/Character.vue";
import {
  addGroupItem,
  getGroup,
  getGroupCharacter, getGroupItems,
  getGroupMember, popGroupItem,
  saveGroupCharacter, updateGroupItem
} from "@/services/api";
import {Character} from "csrpglib";
import GroupItems from "@/components/GroupItems";
import {WS_URL} from "@/services/websocket";

export default {
  name: "Group",
  components: {
    CharacterC,
    Market,
    GroupItems
  },
  data: () => ({
    characterId: 0,
    members: [],
    group: {},
    itemCat: "itemGroup",
    connection: null
  }),
  created() {
    const self = this
    console.log("Starting connection to WebSocket Server")
    this.connection = new WebSocket(WS_URL + "/" + this.$route.params.groupId + "/" + this.$store.getters.userId)

    this.connection.onmessage = async function (event) {
      // console.log(event);
      const msg = JSON.parse(event.data);
      switch (msg.type) {
        case "updateGroupItems":
          self.group.inventory.items = await getGroupItems(self.group.id)
          break
        case "updateCharacter":
            if (msg.character_id === self.characterId){
              await self.$refs.character.reloadCharacter()
            }
          break
      }
    }

    this.connection.onopen = function (event) {
      console.log("Successfully connected to the websocket...")
    }
  },
  methods: {
    sendMessage: function (message) {
      this.connection.send(message);
    },
    selectCat: function (itemCat) {
      this.itemCat = itemCat
    },
    showCat: function (itemCat) {
      return itemCat === this.itemCat
    },
    addNewGroupItem: async function (item) {
      if (this.group.inventory.items === null) {
        this.group.inventory.items = []
      }
      item.quantity = 1
      await addGroupItem(this.group.id, item)
      // this.group.inventory.items.push(item)
      const msg = {
        type: "updateGroupItems",
      };
      this.sendMessage(JSON.stringify(msg))
    },
    updateGroupItem: async function (item) {
      await updateGroupItem(this.group.id, item)
      const msg = {
        type: "updateGroupItems",
      };
      this.sendMessage(JSON.stringify(msg))
    },
    pickGroupItem: async function (item) {

      const deletedItem = await popGroupItem(this.group.id, item.hash)
      if (deletedItem !== null) {
        this.$refs.character.pick_items(deletedItem)
      } else {
        alert("Too late")
      }
      const msg = {
        type: "updateGroupItems",
      };
      this.sendMessage(JSON.stringify(msg))
      this.group = await getGroup(this.$route.params.groupId)
    },
    pickItem: function (item) {
      this.$refs.character.pick_items(item)
    },
    buyItem: function (item) {
      this.$refs.character.buy_items(item)
    },
    saveCharacter: async function (characterId, character) {
      await saveGroupCharacter(this.$route.params.groupId, characterId, character.get_json()).catch((err) => {
        console.log(err)
        alert("erreur de communication avec le serveur");
      });
      const msg = {
        type: "updateCharacter",
        user_id:this.$store.getters.userId,
        character_id: characterId
      };
      this.sendMessage(JSON.stringify(msg))
    },
    loadCharacter: async function (characterId) {
      let response = await getGroupCharacter(this.$route.params.groupId, characterId).catch((err) => {
        console.log(err)
        alert("erreur de communication avec le serveur");
      });
      if (response.inventory === undefined){
        response.light = true
        return response
      }else{
        return Character.new_from_json(JSON.stringify(response));
      }

    },
    selectCharacter(character) {
      if (this.group.user_id === this.$store.getters.userId || character.user_id === this.$store.getters.userId) {
        this.characterId = character.id
      }else{
        this.characterId = character.id
      }
    }
  },
  async mounted() {
    this.group = await getGroup(this.$route.params.groupId).catch((err) => {
      console.log(err)
      alert("erreur de communication avec le serveur");
    });
    this.characterId = this.group.users[this.$store.getters.userId][0]

    this.members = await getGroupMember(this.$route.params.groupId).catch((err) => {
      console.log(err)
      alert("erreur de communication avec le serveur");
    });

  },
};
</script>
<style>
.status-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 98px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.80);
  display: flex;
  text-align: center;
}

.invisible-div {
  height: 150px;
}

.member {
  max-width: 200px;
  margin: 5px;
}

.member.selected {
  border: 1px solid red;
}

.status-bar .avatar {
  width: 64px;
  height: 64px;
  background-size: 100%;
}

.section-container {
  display: flex;
}

.section-container div {
  margin: 0;
}


.select-group-item {
  background-image: url(../assets/img/icons/deal.png);
}

.select-market {
  background-image: url(../assets/img/icons/bag.png);
}

.select-menu {
  margin: 0;
  border-right: none;
  margin-left: 1em;
}

.select-menu .selected {
  border: 1px solid white;
  border-left: 0;
  border-right: 0;
}

.select-menu span {
  display: block;
  background-size: 100%;
  width: 64px;
  height: 64px;
}

.item-picker section {
  border-left-width: 0;
}

.item-picker section.details, .item-picker section.new {
  border-left-width: 1px;
}
</style>