<template>
  <div class="value_currency_form" >
    <label for="value_platinum" class="currency platinum"></label>
    <input v-model.number="cur.platinum" id="value_platinum" type="number"/>
    <label for="value_gold" class="currency gold"></label>
    <input v-model.number="cur.gold" id="value_gold" type="number"/>
    <label for="value_silver" class="currency silver"></label>
    <input v-model.number="cur.silver" id="value_silver" type="number"/>
    <label for="value_copper" class="currency copper"></label>
    <input v-model.number="cur.copper" id="value_copper" type="number"/>
  </div>
</template>

<script>
export default {
  name: "EditMoney",
  data() {
    return {
      cur: this.currency,
    }
  },
  props: {
    currency: {}
  },
  watch:{
    currency: function(newVal){
      this.cur = newVal
    }
  }
}
</script>
