<template>
  <div id="market" v-if="items">
    <ItemPicker title="Market" :buy_item_handler="buy_item_handler"
                :pick_item_handler="pick_item_handler" :create_handler="addNewItem"
                :update_item_handler="updateItem" :items_prop="items"/>
  </div>
</template>
<script>

import {createItem, getItems, updateItem} from "@/services/api";
import ItemPicker from "@/components/ItemPicker";

export default {
  name: 'Market',
  data: () => ({
    items: {}
  }),
  components: {
    ItemPicker
  },
  props: {
    pick_item_handler: {type: Function},
    buy_item_handler: {type: Function},
  },
  methods: {
    addNewItem: async function (item) {
      const result = await createItem(item).catch((err) => {
        console.log(err)
        alert("erreur de communication avec le serveur");
      });
      this.items.push(result)
    },
    updateItem: async function (item) {
      await updateItem(item).catch((err) => {
        console.log(err)
        alert("erreur de communication avec le serveur");
      });
    }
  },
  async mounted() {
    this.items = await getItems().catch((err) => {
      console.log(err)
      alert("erreur de communication avec le serveur");
    });
  },
}
</script>



