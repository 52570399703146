<template>
  <Modal
      v-show="IsGroupModalVisible"
      @close="closePopup"
      @confirm="validateDeleteGroup"
  >
    <template v-slot:header>
      Effacer le Groupe?
    </template>

    <template v-slot:body>
      <p>Est ce que tu es bien sur de vouloir effacer ce groupe?</p>
      <p>Non, parce que y a pas moyen de le recuperer apres.</p>
    </template>
  </Modal>

  <Modal
      v-show="IsCharacterModalVisible"
      @close="closeUserPopup"
      @confirm="validateDeleteCharacter"
  >
    <template v-slot:header>
      Effacer le perso?
    </template>

    <template v-slot:body>
      <p>Est ce que tu es bien sur de vouloir effacer ce perso?</p>
      <p>Non, parce que y a pas moyen de le recuperer apres.</p>
    </template>
  </Modal>
  

  <div class="list-character">
    <section v-for="character in characters" :key="character.id" id="summary-container"
             draggable="true" @dragstart="startDrag($event, character)">
      <button class="delete-btn group" @click="deleteCharacter(character.id)">X </button>
      <div v-on:click="selectCharacter(character.id)">
        <h2 class="center"> {{ character.name }}</h2>
        <div class="center"> {{ character_class(character.class) }} {{ character_race(character.race) }}</div>
      </div>
    </section>
    <section v-on:click="new_character()">
      <div class="center">Nouveau perso</div>
    </section>
  </div>

  <div class="list-group">
    <section v-for="group in groups" :key="group.id" id="summary-group-container"
             @drop='onDrop($event, group)'
             @dragover.prevent
             @dragenter.prevent
    >
      <button class="delete-btn group" @click="deleteGroup(group.id)">X </button>
      <div @click="selectGroup(group.id)">
        <h2 class="center"> {{ group.name }}</h2>
      </div>

      <div v-for="characterId in group.users[this.$store.getters.userId]" :key="characterId">
        <button class="delete-btn" @click="deleteMember(group.id, characterId)">X </button>{{ getCharacter(characterId).name }}
      </div>
      <button v-if="group.user_id === this.$store.getters.userId"  @click="editGroup(group.id)">Edit</button>
    </section>
    <section @click="this.$router.push('/newgroup')">
      <div class="center">Nouveau groupe</div>
    </section>
  </div>
</template>
<script>
import {
  addMemberToGroup,
  deleteCharacter,
  deleteGroup,
  deleteMemberFromGroup,
  getCharacters,
  getGroups
} from '@/services/api.js'
import {classMapFr, raceFr} from "@/const";
import Modal from "@/components/Modal";

export default {
  name: "Selection",
  data: () => ({
    characters: [],
    groups: [],
    IsGroupModalVisible: false,
    IsCharacterModalVisible: false,
    idToDelete:null
  }),
  components:{
    Modal
  },
  methods: {
    editGroup(groupId){
      this.$router.push('/editgroup/' + groupId)
    },
    selectGroup: function (id) {
      this.$router.push('/group/' + id)
    },
    selectCharacter: function (id) {
      this.$router.push('/character/' + id)
    },
    new_character: function (id) {
      this.$router.push('/new')
    },
    character_class(cl) {
      return classMapFr[cl]
    },
    character_race(race) {
      return raceFr[race]
    },
    getCharacter(characterId) {
      return this.characters.find(character => character.id === characterId)
    },
    startDrag: (evt, character) => {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('characterId', character.id)
    },
    async deleteMember(groupId, characterId) {
      await deleteMemberFromGroup(groupId, characterId)
      this.groups = await getGroups().catch((err) => {
        alert(err)
        console.log(err)
      });
    },
    deleteGroup(groupId) {
      this.idToDelete = groupId
      this.IsGroupModalVisible = true
    },
    deleteCharacter(characterId) {
      this.idToDelete = characterId
      this.IsCharacterModalVisible = true
    },
    closePopup() {
      this.IsGroupModalVisible= false
    },
    closeUserPopup() {
      this.IsCharacterModalVisible= false
    },
    async validateDeleteGroup() {
      await deleteGroup(this.idToDelete)
      this.IsGroupModalVisible = false
      this.idToDelete = null
      this.groups = await getGroups()
    },
    async validateDeleteCharacter() {
      await deleteCharacter(this.idToDelete)
      this.IsCharacterModalVisible = false
      this.idToDelete = null
      this.characters = await getCharacters()
    },
    async onDrop(evt, group) {
      const characterId = evt.dataTransfer.getData('characterId')
      // const character = this.characters.find(character => character.id === characterId)
      // alert(character.id +" " + group.id)
      await addMemberToGroup(group.id, characterId)
      this.groups = await getGroups().catch((err) => {
        alert(err)
        console.log(err)
      });
    }
  },
  async mounted() {
    this.characters = await getCharacters().catch((err) => {
      console.log(err)
    });

    this.groups = await getGroups().catch((err) => {
      console.log(err)
    });
  },
};
</script>
<style>
.list-character, .list-group {
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
}

.list-character section, .list-group section {
  margin: 10px;
  min-width: 140px;
}

.list-group section {
  background-color: #5b698744;
}

.list-group, .list-group {
  margin-top: 0;
  padding-top: 0;
}

.delete-btn {
  /*display: inline-block;*/
  /*background-color: red;*/
  /*border: 1px solid white;*/
  /*padding: 2px;*/
  /*border-radius: 5px;*/
  /*margin-right: 5px;*/
  /*line-height: 1em;*/
  /*color: white;*/
  /*font-weight: bold;*/
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  color: var(--pastel-red);
  background: transparent;
  margin-bottom: 5px;
}

.group {
  position: absolute;
  top: 0;
  right: 0;
}
</style>