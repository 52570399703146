<template>
  <div class="i-type form-fields inline">
    <select v-model="selectedKey" v-on:change="selectKey">
      <option value="" disabled selected>Selectionnez une categorie</option>
      <option v-for="key in keys" :key="key">{{ key }}</option>
    </select>
    <ul v-if="selectedKey" class="items thumb">
      <li v-for="item in items" :key="item" class="item" v-bind:class="[item]"
          v-on:click="selectItypeHandler(item)"></li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'ItypeSelector',
  data: () => ({
    // keys: Object.keys(this.img_data),
    selectedKey: '',
    items: Array
    // item_type:null
  }),
  props: {
    selectItypeHandler: {},
    img_data:{}
  },
  methods: {
    selectKey: function () {
      this.items = this.img_data[this.selectedKey]
    }
  },
  computed:{
    keys:function (){
      return Object.keys(this.img_data)
    }
  }
}
</script>
<style>
.i-type .items{
  max-height: none;
}
.i-type .item{
  margin: 1px;
}
</style>