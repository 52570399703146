<template>
  <div class="item-detail">
    <h3>{{ item.name }} <button v-if="editHandler" v-on:click="editHandler">Edit</button></h3>

    <div class="item" v-bind:class="[item.i_type]"></div>
    <ul v-if="!hideDetails">
      <li>{{ item.weight }} kg</li>
      <li v-if="item.magical">Magical: {{ item.magic }}</li>

      <li v-if="item.speed_factor">Speed factor: {{ item.speed_factor }}</li>
      <li v-if="item.damage">damage: small: {{ item.damage.small }}, Large: {{ item.damage.large }}</li>
      <li v-if="item.armor_class">Armor Class:{{ item.armor_class }}</li>
      <li v-if="item.quantity && update_quantity_handler"><button @click="downQuantity">-</button>Quantité:{{ quantity }}<button @click="upQuantity">+</button> </li>
    </ul>
    <div class="description" v-if="item.description && !hideDetails">{{ item.description }}</div>
    <Money v-if="!item.value.unknown && !hideDetails" :currency="item.value.currency"/>
  </div>
</template>
<script>
import Money from './Money.vue'

export default {
  name: 'ItemDetail',
  components: {
    Money,
  },
  props: {
    item: {},
    editHandler:{type:Function},
    update_quantity_handler:{type:Function},
    save_item_handler:{type:Function},
    hideDetails:Boolean,
  },
  data: () => ({
    quantity: 0,
    timeout:0,
  }),
  methods:{
    upQuantity(){
      clearTimeout(this.timeout)
      this.timeout = this.startTimer()
      this.quantity ++
      this.update_quantity_handler(this.quantity)
    },
    downQuantity(){
      clearInterval(this.timeout)
      this.timeout = this.startTimer()
      if (this.quantity >1){
        this.quantity --
      }
      this.update_quantity_handler(this.quantity)
    },
    startTimer(){
      return setTimeout(() => {
          this.save_item_handler()
      }, 1000);
    }
  },
  mounted() {
    this.quantity = this.item.quantity

  },
  watch:{
    item:function(newVal){
      this.quantity = newVal.quantity
    }
  }
}
</script>