const bodyParts = {
    Head: 0,
    LeftHand: 1,
    RightHand: 2,
    Torso: 3,
    Legs: 4,
    Foot: 5,
}

export const equipmentBodyPart = {
    Head: "Head",
    OneHand: "OneHand",
    TwoHands: "TwoHands",
    OneOrTwoHands: "OneOrTwoHands",
    Torso: "Torso",
    Legs: "Legs",
    Foot: "Foot",
    Finger: "Foot"
}

export const raceFr = {
    0: "Humain",
    "Human": "Humain",
    1: "Nain",
    "Dwarf": "Nain",
    2: "Elf",
    "Elf": "Elf"
}

export const classMapFr = {
    0: "Guerrier",
    "Warrior": "Guerrier",
    1: "Paladin",
    "Paladin": "Paladin",
    2: "Rôdeur",
    "Ranger": "Rôdeur",
    3: "Mage",
    "Mage": "Mage",
    4: "Illusionniste",
    "Illusionist": "Illusionniste",
    5: "Clerc",
    "Cleric": "Clerc",
    6: "Druide",
    "Druid": "Druide",
    7: "Voleur",
    "Thief": "Voleur",
    8: "Barde",
    "Bard": "Barde"
}
export default bodyParts