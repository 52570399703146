<template>
  <section id="inventory-container">
    <h3>Inventaire</h3>
    <Money :currency="character.money" :money_change_handler="money_change_handler"/>
    <Items :items="items" :free_slots="character.available_item_slots()"
           :click_handler="select_item"
           :selected_index="selected_index"/>
    <button v-on:click="create_new_item">Creer un nouvel objet</button>
    {{ character.carrying_weight().toFixed(2) }}kg / {{ character.strength_attributes().maximum_press }}kg
  </section>
  <section v-if="selected_item">
    <button class="close-btn" v-on:click="close">X</button>
    <Item :item_selected="selected_item"
          :edit_state="edit_item"
          :update_item_handler="update_character_item"
          :editStart="edit_start"
          :editEnd="edit_end"
          :allow-quantity="true"
    />
    <div v-if="!edit_item">
      <button v-on:click="drop_item(selected_index)">Drop item</button>
      <button
          v-if="canBeEquipedOnTheHead"
          v-on:click="equip_item(selected_index,bodyParts.Head)">Equiper tête
      </button>

      <button
          v-if="canBeEquipedInHand"
          v-on:click="equip_item(selected_index,bodyParts.LeftHand)">Equiper main gauche
      </button>
      <button
          v-if="canBeEquipedInHand"
          v-on:click="equip_item(selected_index,bodyParts.RightHand)">Equiper main droite
      </button>
      <button
          v-if="canBeEquipedOnFoot"
          v-on:click="equip_item(selected_index,bodyParts.Foot)">Equiper pieds
      </button>
      <button
          v-if="canBeEquipedOnLegs"
          v-on:click="equip_item(selected_index,bodyParts.Legs)">Equiper jambes
      </button>
      <button v-if="selected_item.type==='Armor'" v-on:click="equip_item(selected_index,bodyParts.Torso)">Equip armor
      </button>
      <button v-if="group" v-on:click="sendToGroupItem(selected_index)">Mettre dans les objets du groupe</button>
    </div>

  </section>
  <section v-if="new_item">
    <NewItem :new_item_handler="add_new_item"/>
  </section>
</template>
<script>

import Items from './Items.vue'
import Item from './Item.vue'
import Money from './Money.vue'
import NewItem from "@/components/NewItem";
import bodyParts, {equipmentBodyPart} from "@/const";

export default {
  name: 'Inventory',
  data: () => ({
    selected_index: -1,
    selected_item: null,
    new_item: false,
    edit_item: false,
    bodyParts: bodyParts
  }),
  components: {
    Items,
    Item,
    Money,
    NewItem
  },
  props: {
    character: {},
    items: Array,
    drop_item_handler: {type: Function},
    equip_item_handler: {type: Function},
    update_character_item_handler: {type: Function},
    new_item_handler: {type: Function},
    money_change_handler: {type: Function},
    send_to_group_handler: {type: Function},
    group:Boolean,
  }, methods: {
    close:function (){
      this.selected_item =null
      this.selected_index = -1
    },
    update_character_item: function (item) {
      this.update_character_item_handler(this.selected_index, item)
    },
    select_item: function (index) {
      this.selected_index = index
      this.selected_item = this.items[this.selected_index]
      this.new_item = false
      this.edit_item = false
    },
    drop_item: function (index) {
      this.drop_item_handler(index)
      this.selected_index = -1
      this.selected_item = null
    },
    sendToGroupItem: function (index){
      this.send_to_group_handler(index)
      this.selected_index = -1
      this.selected_item = null
    },
    equip_item: function (index, body_part) {
      this.equip_item_handler(index, body_part)
      this.selected_index = -1
      this.selected_item = null
    },
    create_new_item: function () {
      this.new_item = true
      this.selected_index = -1
      this.selected_item = null
    },
    add_new_item: function (item) {
      this.new_item_handler(item)
      this.selected_index = this.items.length
      this.selected_item = item
      this.new_item = false
      this.edit_item = true
    },
    edit_start: function () {
      this.edit_item = true
    },
    edit_end: function () {
      this.edit_item = false
    },
  }, computed: {
    canBeEquipedInHand: function () {
      let selected_item = this.selected_item
      return selected_item.type === 'Weapon' || selected_item.type === 'Shield' || selected_item.type === 'RangedWeapon' || (selected_item.type === 'Item' && selected_item.equipable && (selected_item.body_part === equipmentBodyPart.OneHand || selected_item.body_part === equipmentBodyPart.TwoHands ||selected_item.body_part === equipmentBodyPart.OneOrTwoHands))
    },
    canBeEquipedOnTheHead: function(){
      let selected_item = this.selected_item
      return selected_item.type==='Item' && selected_item.equipable && selected_item.body_part === equipmentBodyPart.Head
    },
    canBeEquipedOnFoot: function(){
      let selected_item = this.selected_item
      return selected_item.type==='Item' && selected_item.equipable && selected_item.body_part === equipmentBodyPart.Foot
    },
    canBeEquipedOnLegs: function(){
      let selected_item = this.selected_item
      return selected_item.type==='Item' && selected_item.equipable && selected_item.body_part === equipmentBodyPart.Legs
    },
  }
}
</script>

<style>

</style>
