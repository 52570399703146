<template>
  <div class="main-container">
    <form @submit.prevent="login">
      <div class="box-container">
        <h2 class="heading">Sign In</h2>
        <div class="form-fields">
          <input v-model="username" id="username" name="username" type="text" placeholder="username">
        </div>
        <div class="form-fields">
          <input v-model="password" id="password" name="password" type="password" placeholder="Password">
        </div>
        <div v-if="errorMsg" class="error-msg">
          {{ errorMsg }}
        </div>
        <div class="form-fields">
          <button class="signIn" name="commit" type="submit">
            Sign In
          </button>
        </div>
        <!-- <div class="login-choice"><span>or Sign In with</span></div> -->
        <div class="login-choice">Pas de compte?
          <router-link to="/signup">Créer un compte</router-link>
        </div>

      </div>
    </form>
    <div class="footer">
      <p></p>
    </div>
  </div>
</template>

<script>
import {login} from '@/services/api.js'

export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      errorMsg: ''
    }
  },
  methods: {
    async login() {

      let result = await login(this.username, this.password).catch(err => {
        console.log(err)
        alert("erreur de communication avec le serveur");
      })
      if (result.success) {
        this.$router.push('/')
      } else {
        this.errorMsg = "Login/password incorrect"
      }
    }
  }
}
</script>
<style>
.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px,1fr) auto minmax(20px,1fr);
  grid-template-columns: minmax(20px,1fr) auto minmax(20px,1fr);
  grid-gap: 19px;
}
.login-choice span:after, .login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}

</style>

