<template>
  <section id="summary-container">
    <h2 class="center"> {{ character.name }}</h2>

    <div class="avatar" v-bind:class="[character.avatar]">
    </div>
    <div class="center"> {{ character_class }} Niveau {{level}}</div>
    <div class="center">
      <span >{{ character.xp }}</span>XP
    </div>
    <div class="health" >
      <div class="light-grey">
        <div  class="green center" v-bind:class="healthClass" v-bind:style="{width:health +'%'}">&nbsp;</div>
      </div>
    </div>
  </section>
</template>
<script>

import {classMapFr, raceFr} from "@/const";
import * as wasm from "csrpglib";

export default {
  name: 'summary-light',
  props: {
    character: {},
  },
  computed: {
    level(){
      return wasm.level(this.character.class, this.character.xp)
    },
    character_class() {
      return classMapFr[this.character.class]
    },
    character_race() {
      return raceFr[this.character.race]
    },
    health() {
      return this.character.hp / this.character.max_hp * 100
    },
    healthClass(){
      const health = this.health
      if (health>50){
        return "good"
      }else if(health>25){
        return "medium"
      }else{
        return "bad"
      }
    }
  },
}
</script>
