<template>
  <router-link to="/">Back</router-link>
  <form @submit.prevent="createNewCharacter" class="create-new-form">
    <div class="box-container new-character-box">
      <h2 class="heading">Nouveau perso</h2>
      <div class="field-container">
        <div class="character-fields">
          <div class="form-fields">
            <label for="name">Nom</label>
            <input v-model="name" id="name" name="name" type="text" placeholder="Name"/>
          </div>
          <div class="form-fields">
            <label for="Race">Force</label>
            <select v-model="race" id="race" name="race">
              <option value="Human">Human</option>
              <option value="Dwarf">Dwarf</option>
              <option value="Elf">Elf</option>
            </select>
          </div>
          <div class="form-fields">
            <label for="c_class">Class</label>
            <select v-model="c_class" id="c_class" name="c_class">
              <optgroup label="Combattant">
                <option value="Warrior">Guerrier</option>
                <option value="Paladin">Paladin</option>
                <option value="Ranger">Rôdeur</option>
              </optgroup>
              <optgroup label="Magicien">
                <option value="Mage">Mage</option>
                <option value="Illusionist">Illusionniste</option>
              </optgroup>
              <optgroup label="Priest">
                <option value="Cleric">Clerc</option>
                <option value="Druid">Druide</option>
              </optgroup>
              <optgroup label="Roublard">
                <option value="Thief">Voleur</option>
                <option value="Bard">Barde</option>
              </optgroup>

            </select>
          </div>
          <div class="form-fields">
            <label for="gender">Sexe</label>
            <select v-model="gender" id="gender" name="gender">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <div class="attribute-container">
          <div class="form-fields">
            <label for="hp">Hp</label>
            <input v-model.number="hp" id="hp" name="hp" type="number" min="1"/>
          </div>
          <div class="form-fields">
            <label for="age">Age</label>
            <input v-model.number="age" id="age" name="age" type="number" min="1"/>
          </div>
          <div class="form-fields">
            <label for="strength">Force</label>
            <input v-model.number="strength" id="strength" name="strength" type="number" min="1" max="20"/>
          </div>
          <div class="form-fields">
            <label for="dexterity">Dextérité</label>
            <input v-model.number="dexterity" id="dexterity" name="dexterity" type="number" min="1" max="20"/>
          </div>
          <div class="form-fields">
            <label for="constitution">Constitution</label>
            <input v-model.number="constitution" id="constitution" name="constitution" type="number" min="1"
                   max="20"/>
          </div>
          <div class="form-fields">
            <label for="intelligence">Intelligence</label>
            <input v-model.number="intelligence" id="intelligence" name="intelligence" type="number" min="1" max="20"/>
          </div>
          <div class="form-fields">
            <label for="wisdom">Sagesse</label>
            <input v-model.number="wisdom" id="wisdom" name="wisdom" type="number" min="1" max="20"/>
          </div>
          <div class="form-fields">
            <label for="charisma">Charisme</label>
            <input v-model.number="charisma" id="charisma" name="charisma" type="number" min="1" max="20"/>
          </div>
        </div>
      </div>

      <div class="form-fields">
        <button class="signIn" name="commit" type="submit">
          Create
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import {newCharacter} from '@/services/api.js'

export default {
  name: "new",
  data() {
    return {
      name: '',
      race: 'Human',
      c_class: 'Warrior',
      gender: 'Male',
      age: 28,
      hp: 10,
      strength: 10,
      dexterity: 10,
      constitution: 10,
      intelligence: 10,
      wisdom: 10,
      charisma: 10,

    }
  },
  methods: {
    async createNewCharacter() {
      let result = await newCharacter(this.name, this.race, this.c_class, this.gender, this.age, this.hp, this.strength, this.dexterity, this.constitution, this.intelligence, this.wisdom, this.charisma).catch(err => {
        alert(err)
      })
      if (result.success) {
        this.$router.push('/')
      }
    }
  }
};
</script>
<style>

.character-fields {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
}

.attribute-container {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
}

.attribute-container .form-fields {
  width: 45%;
}

.attribute-container .form-fields input {
  width: 60%;
}
</style>