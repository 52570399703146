<template>
  <router-link to="/">Back</router-link>
  <form @submit.prevent="createNewGroup" class="create-new-form" >
    <div class="box-container new-character-box">
      <h2 class="heading">Nouveau groupe</h2>
      <div class="field-container">
        <div class="form-fields">
          <label for="name">Nom</label>
          <input v-model="name" id="name" name="name" type="text" placeholder="Name"/>
        </div>
      </div>

      <div v-if="options">
        <Multiselect v-model="users" :options="options"
                     :searchable="true"
                     placeholder="Select users"
                     mode="tags"
        >

        </Multiselect>
      </div>

      <div class="form-fields">
        <button class="signIn" name="commit" type="submit">
          Create
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import {createGroup, getUsers} from '@/services/api.js'
import Multiselect from '@vueform/multiselect'

export default {
  name: "NewGroup",
  components:{
    Multiselect
  },
  data() {
    return {
      name: '',
      users:[],
      options:[],
    }
  },
  methods: {
    async createNewGroup() {
      let users = {}
      this.users.forEach(userid =>{
        users[userid]=[]
      })

      let result = await createGroup({name:this.name, users})

      if (result.success) {
        this.$router.push('/')
      }
    }
  },
  async mounted() {
    const users = await getUsers().catch((err) => {
      console.log(err)
    });
    users.forEach(user => this.options.push({value:user.id, label:user.username}) )
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
