<template>
  <form @submit.prevent="submitForm" class="create-new-form" >
    <div class="box-container new-character-box">
      <h2 class="heading">{{title}}</h2>
      <div class="field-container">
        <div class="form-fields">
          <label for="name">Nom</label>
          <input v-model="name" id="name" name="name" type="text" placeholder="Name"/>
        </div>
      </div>

      <div v-if="options">
        <Multiselect v-model="users" :options="options"
                     :searchable="true"
                     placeholder="Select users"
                     mode="tags"
        >

        </Multiselect>
      </div>

      <div class="form-fields">
        <button class="signIn" name="commit" type="submit">
          {{btnText}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { getUsers} from "@/services/api";
import Multiselect from "@vueform/multiselect";

export default {
  name: "GroupForm",
  props:{
    submit_handler:{Type:Function},
    title:String,
    btnText:String,
    group: {}
  },
  components:{
    Multiselect
  },
  data() {
    return {
      name: this.group.name,
      users:[],
      options: [],
    }
  },
  methods:{
    submitForm(){
      let users = {}
      this.users.forEach(userid =>{
        users[userid]=[]
      })
      let group =this.group
      group.name = this.name
      group.users = users
      this.submit_handler(group)
    }
  },
  async mounted() {
    const users = await getUsers().catch((err) => {
      console.log(err)
    });
    users.forEach(user => this.options.push({value:user.id, label:user.username}) )

    for (const [key, value] of Object.entries(this.group.users)) {
      this.users.push(key)
    }
  },
}
</script>

<style scoped>

</style>