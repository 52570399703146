import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Selection from '@/views/Selection.vue'
import New from '@/views/New.vue'
import Login from '@/views/Login'
import Signup from '@/views/Signup'
import store from '@/store.js'
import Group from "@/views/Group";
import NewGroup from "@/views/NewGroup";
import EditGroup from "@/views/EditGroup";

const routes = [
  {
    path: '/character/:id',
    name: 'Character',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/group/:groupId',
    name: 'Group',
    component: Group,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Selection',
    component: Selection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new',
    name: 'New',
    component: New,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newgroup',
    name: 'NewGroup',
    component: NewGroup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editgroup/:groupId',
    name: 'EditGroup',
    component: EditGroup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})


export default router
