import * as wasm from './csrpglib_bg.wasm';

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function getObject(idx) { return heap[idx]; }

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}
/**
* @param {string} object_type
* @param {string} name
* @returns {any}
*/
export function create_object(object_type, name) {
    var ptr0 = passStringToWasm0(object_type, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    var ptr1 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    var ret = wasm.create_object(ptr0, len0, ptr1, len1);
    return takeObject(ret);
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}
/**
* @param {Character} c
* @param {string} input
*/
export function pick_items(c, input) {
    _assertClass(c, Character);
    var ptr0 = passStringToWasm0(input, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    wasm.pick_items(c.ptr, ptr0, len0);
}

/**
* @param {Character} c
* @param {number} index
*/
export function drop_item(c, index) {
    _assertClass(c, Character);
    wasm.drop_item(c.ptr, index);
}

/**
* @param {Character} c
* @param {number} index
* @param {string} input
*/
export function update_character_item(c, index, input) {
    _assertClass(c, Character);
    var ptr0 = passStringToWasm0(input, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    wasm.update_character_item(c.ptr, index, ptr0, len0);
}

/**
* @param {Character} c
* @param {string} input
*/
export function add_character_item(c, input) {
    _assertClass(c, Character);
    var ptr0 = passStringToWasm0(input, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    wasm.add_character_item(c.ptr, ptr0, len0);
}

/**
* @param {Character} c
* @param {string} input
* @returns {boolean}
*/
export function buy_item(c, input) {
    _assertClass(c, Character);
    var ptr0 = passStringToWasm0(input, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    var ret = wasm.buy_item(c.ptr, ptr0, len0);
    return ret !== 0;
}

/**
* @param {Character} c
*/
export function greet(c) {
    _assertClass(c, Character);
    wasm.greet(c.ptr);
}

/**
* @param {number} cls
* @param {number} xp
* @returns {number}
*/
export function level(cls, xp) {
    var ret = wasm.level(cls, xp);
    return ret >>> 0;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}
/**
* @param {Character} c
* @param {number} index
* @param {number} body_part
*/
export function equip_item(c, index, body_part) {
    _assertClass(c, Character);
    wasm.equip_item(c.ptr, index, body_part);
}

/**
* @param {Character} c
* @param {number} body_part
*/
export function unequip_item(c, body_part) {
    _assertClass(c, Character);
    wasm.unequip_item(c.ptr, body_part);
}

/**
*/
export const Race = Object.freeze({ Human:0,"0":"Human",Dwarf:1,"1":"Dwarf",Elf:2,"2":"Elf", });
/**
*/
export const Class = Object.freeze({ Warrior:0,"0":"Warrior",Paladin:1,"1":"Paladin",Ranger:2,"2":"Ranger",Mage:3,"3":"Mage",Illusionist:4,"4":"Illusionist",Cleric:5,"5":"Cleric",Druid:6,"6":"Druid",Thief:7,"7":"Thief",Bard:8,"8":"Bard", });
/**
*/
export const Gender = Object.freeze({ Male:0,"0":"Male",Female:1,"1":"Female", });
/**
*/
export const BodyPart = Object.freeze({ Head:0,"0":"Head",LeftHand:1,"1":"LeftHand",RightHand:2,"2":"RightHand",Torso:3,"3":"Torso",Legs:4,"4":"Legs",Foot:5,"5":"Foot", });
/**
*/
export const EquipmentBodyPart = Object.freeze({ Head:0,"0":"Head",OneHand:1,"1":"OneHand",TwoHands:2,"2":"TwoHands",OneOrTwoHands:3,"3":"OneOrTwoHands",Torso:4,"4":"Torso",Legs:5,"5":"Legs",Foot:6,"6":"Foot",Finger:7,"7":"Finger", });
/**
*/
export class Character {

    static __wrap(ptr) {
        const obj = Object.create(Character.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_character_free(ptr);
    }
    /**
    * @returns {number}
    */
    get race() {
        var ret = wasm.__wbg_get_character_race(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set race(arg0) {
        wasm.__wbg_set_character_race(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get class() {
        var ret = wasm.__wbg_get_character_class(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set class(arg0) {
        wasm.__wbg_set_character_class(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get gender() {
        var ret = wasm.__wbg_get_character_gender(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set gender(arg0) {
        wasm.__wbg_set_character_gender(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get hp() {
        var ret = wasm.__wbg_get_character_hp(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set hp(arg0) {
        wasm.__wbg_set_character_hp(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get max_hp() {
        var ret = wasm.__wbg_get_character_max_hp(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set max_hp(arg0) {
        wasm.__wbg_set_character_max_hp(this.ptr, arg0);
    }
    /**
    * @returns {Currency}
    */
    get money() {
        var ret = wasm.__wbg_get_character_money(this.ptr);
        return Currency.__wrap(ret);
    }
    /**
    * @param {Currency} arg0
    */
    set money(arg0) {
        _assertClass(arg0, Currency);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_character_money(this.ptr, ptr0);
    }
    /**
    * @returns {number}
    */
    get strength() {
        var ret = wasm.__wbg_get_character_strength(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set strength(arg0) {
        wasm.__wbg_set_character_strength(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get dexterity() {
        var ret = wasm.__wbg_get_character_dexterity(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set dexterity(arg0) {
        wasm.__wbg_set_character_dexterity(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get constitution() {
        var ret = wasm.__wbg_get_character_constitution(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set constitution(arg0) {
        wasm.__wbg_set_character_constitution(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get intelligence() {
        var ret = wasm.__wbg_get_character_intelligence(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set intelligence(arg0) {
        wasm.__wbg_set_character_intelligence(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get wisdom() {
        var ret = wasm.__wbg_get_character_wisdom(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set wisdom(arg0) {
        wasm.__wbg_set_character_wisdom(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get charisma() {
        var ret = wasm.__wbg_get_character_charisma(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set charisma(arg0) {
        wasm.__wbg_set_character_charisma(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get xp() {
        var ret = wasm.__wbg_get_character_xp(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set xp(arg0) {
        wasm.__wbg_set_character_xp(this.ptr, arg0);
    }
    /**
    * @param {string} input
    * @returns {Character}
    */
    static new_from_json(input) {
        var ptr0 = passStringToWasm0(input, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        var ret = wasm.character_new_from_json(ptr0, len0);
        return Character.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    name() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.character_name(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(r0, r1);
        }
    }
    /**
    * @param {string} name
    */
    set_name(name) {
        var ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        wasm.character_set_name(this.ptr, ptr0, len0);
    }
    /**
    * @returns {string}
    */
    avatar() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.character_avatar(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(r0, r1);
        }
    }
    /**
    * @param {string} av
    */
    set_avatar(av) {
        var ptr0 = passStringToWasm0(av, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        wasm.character_set_avatar(this.ptr, ptr0, len0);
    }
    /**
    * @param {number} p
    * @param {number} g
    * @param {number} s
    * @param {number} c
    */
    update_money(p, g, s, c) {
        wasm.character_update_money(this.ptr, p, g, s, c);
    }
    /**
    * @returns {StrengthAttributes}
    */
    strength_attributes() {
        var ret = wasm.character_strength_attributes(this.ptr);
        return StrengthAttributes.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    level() {
        var ret = wasm.character_level(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {any}
    */
    get_items() {
        var ret = wasm.character_get_items(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {any}
    */
    get_json() {
        var ret = wasm.character_get_json(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {any}
    */
    get_equipment() {
        var ret = wasm.character_get_equipment(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {number}
    */
    available_item_slots() {
        var ret = wasm.character_available_item_slots(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    carrying_weight() {
        var ret = wasm.character_carrying_weight(this.ptr);
        return ret;
    }
    /**
    * @returns {number}
    */
    thaco() {
        var ret = wasm.character_thaco(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    armor_class() {
        var ret = wasm.character_armor_class(this.ptr);
        return ret;
    }
    /**
    * @returns {number}
    */
    speed_factor() {
        var ret = wasm.character_speed_factor(this.ptr);
        return ret >>> 0;
    }
}
/**
*/
export class Currency {

    static __wrap(ptr) {
        const obj = Object.create(Currency.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_currency_free(ptr);
    }
    /**
    * @returns {number}
    */
    get platinum() {
        var ret = wasm.__wbg_get_currency_platinum(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set platinum(arg0) {
        wasm.__wbg_set_currency_platinum(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get gold() {
        var ret = wasm.__wbg_get_currency_gold(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set gold(arg0) {
        wasm.__wbg_set_currency_gold(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get silver() {
        var ret = wasm.__wbg_get_currency_silver(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set silver(arg0) {
        wasm.__wbg_set_currency_silver(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get copper() {
        var ret = wasm.__wbg_get_currency_copper(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set copper(arg0) {
        wasm.__wbg_set_currency_copper(this.ptr, arg0);
    }
}
/**
*/
export class DexterityAttributes {

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_dexterityattributes_free(ptr);
    }
    /**
    * @returns {number}
    */
    get reaction_adjustment() {
        var ret = wasm.__wbg_get_dexterityattributes_reaction_adjustment(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set reaction_adjustment(arg0) {
        wasm.__wbg_set_dexterityattributes_reaction_adjustment(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get missile_attack_adjustment() {
        var ret = wasm.__wbg_get_dexterityattributes_missile_attack_adjustment(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set missile_attack_adjustment(arg0) {
        wasm.__wbg_set_dexterityattributes_missile_attack_adjustment(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get defensive_adjustment() {
        var ret = wasm.__wbg_get_dexterityattributes_defensive_adjustment(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set defensive_adjustment(arg0) {
        wasm.__wbg_set_dexterityattributes_defensive_adjustment(this.ptr, arg0);
    }
}
/**
*/
export class StrengthAttributes {

    static __wrap(ptr) {
        const obj = Object.create(StrengthAttributes.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_strengthattributes_free(ptr);
    }
    /**
    * @returns {number}
    */
    get hit_probability() {
        var ret = wasm.__wbg_get_strengthattributes_hit_probability(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set hit_probability(arg0) {
        wasm.__wbg_set_strengthattributes_hit_probability(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get damage_adjustment() {
        var ret = wasm.__wbg_get_strengthattributes_damage_adjustment(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set damage_adjustment(arg0) {
        wasm.__wbg_set_strengthattributes_damage_adjustment(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get weight_allowance() {
        var ret = wasm.__wbg_get_strengthattributes_weight_allowance(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set weight_allowance(arg0) {
        wasm.__wbg_set_strengthattributes_weight_allowance(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get maximum_press() {
        var ret = wasm.__wbg_get_strengthattributes_maximum_press(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set maximum_press(arg0) {
        wasm.__wbg_set_strengthattributes_maximum_press(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get open_door() {
        var ret = wasm.__wbg_get_strengthattributes_open_door(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set open_door(arg0) {
        wasm.__wbg_set_strengthattributes_open_door(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get bend_bar_lift_gate() {
        var ret = wasm.__wbg_get_strengthattributes_bend_bar_lift_gate(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set bend_bar_lift_gate(arg0) {
        wasm.__wbg_set_strengthattributes_bend_bar_lift_gate(this.ptr, arg0);
    }
}

export const __wbindgen_string_new = function(arg0, arg1) {
    var ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
};

export const __wbindgen_object_drop_ref = function(arg0) {
    takeObject(arg0);
};

export const __wbindgen_json_parse = function(arg0, arg1) {
    var ret = JSON.parse(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};

export const __wbg_alert_d38c0e930e9b2af5 = function(arg0, arg1) {
    alert(getStringFromWasm0(arg0, arg1));
};

export const __wbg_log_8485ead621ceded9 = function(arg0) {
    console.log(getObject(arg0));
};

export const __wbindgen_throw = function(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

