<template>
<!--  <div id="nav" v-if="isLoggedIn">-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </div>-->
  <a v-if="isLoggedIn" class="logout-link" v-on:click="logout">logout</a>
  <router-view/>
  <span class="version">version {{ $store.getters.appVersion }} - {{env}}</span>
</template>
<script>
import store from "@/store";

export default {
  computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      env: function(){ return process.env.NODE_ENV}
    },
  methods:{
    logout: function(){
      store.commit('logout')
      window.location.href = 'login';
    }
  }
}
</script>

<style>

.logout-link{
  float: right;
}
.version{
  font-size: smaller;
  color: #5b6987;
}

</style>
