<template>
  <div class="item-picker">
    <section>
      <h3>{{ title }}</h3>
      <Items :items="items" :click_handler="selectItem" :selected_index="selectedIndex"/>
      <button v-on:click="createNewItem">Creer un nouvel objet</button>
    </section>
    <section class="details" v-if="selectedItem">
      <button class="close-btn" v-on:click="close">X</button>
      <Item :item_selected="selectedItem" :update_item_handler="update_item_handler" :editStart="editStart"
            :editEnd="editEnd"/>
      <div v-if="selectedIndex > -1">
        <button v-if="pick_item_handler" v-on:click="pickItems">Prendre</button>
        <button v-if="buy_item_handler" v-on:click="buyItems">Acheter</button>
      </div>
    </section>
    <section class="new" v-if="newItem">
      <button class="close-btn" v-on:click="closeNew">X</button>
      <NewItem :new_item_handler="addNewItem"/>
    </section>
  </div>
</template>

<script>
import Items from "@/components/Items";
import Item from "@/components/Item";
import NewItem from "@/components/NewItem";

export default {
  name: "ItemPicker",
  data: () => ({
    selectedIndex: -1,
    selectedItem: null,
    newItem: false,
    items: {}
  }),
  components: {
    Items,
    Item,
    NewItem
  },
  props: {
    items_prop: {},
    title: String,
    pick_item_handler: {type: Function},
    buy_item_handler: {type: Function},
    update_item_handler: {type: Function},
    create_handler: {type: Function},
  }, methods: {
    close: function () {
      this.selectedItem = null
      this.selectedIndex = -1
    },
    closeNew: function () {
      this.newItem = false
    },
    selectItem: function (index) {
      this.newItem = false
      this.selectedIndex = index
      this.selectedItem = this.items[index]
    },
    createNewItem: function () {
      this.newItem = true
      this.selectedIndex = -1
      this.selectedItem = null
    },
    addNewItem: async function (item) {
      let result = await this.create_handler(item).catch((err) => {
        console.log(err)
        alert("erreur de communication avec le serveur");
      });

      this.selectedIndex = this.items.length
      this.selectedItem = result
      this.newItem = false
      this.editItem = true
    },
    editStart: function () {
      this.editItem = true
    },
    editEnd: function () {
      this.editItem = false
    },
    pickItems: function () {
      this.pick_item_handler(this.items[this.selectedIndex])
      this.selectedIndex = -1
      this.selectedItem = null
    },
    buyItems: function () {
      this.buy_item_handler(this.items[this.selectedIndex])
      this.selectedIndex = -1
      this.selectedItem = null
    },
  },
  watch: {
    items_prop: function (newVal) {
      this.items = newVal
    }
  },
  mounted() {
    this.items = this.items_prop
  }
}
</script>

<style>
.item-picker {
  display: flex;
  margin: 1em;
}

.item-picker .items {
  max-width: 664px;
}

.item-picker section {
  min-height: 170px;
}
</style>