import { createStore } from 'vuex'
import VueJwtDecode from "vue-jwt-decode";

// Create a new store instance.
const store = createStore({
    state() {
        return {
            status: '',
            token: localStorage.getItem('token') || '',
            user: {},
            characterId: localStorage.getItem('characterId') || 0,
            admin:false,
            userId:0,
            version: process.env.VUE_APP_VERSION || '0'
        }
    },
    mutations: {

        auth_success(state, token) {
            state.status = 'success'
            state.token = token
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            localStorage.clear()
        },
        characterId(state,id){
            localStorage.setItem('characterId', id)
            state.characterId = id
        },
        admin(state, value){
            state.admin = value
        },
        userId(state, value){
            state.userId = value
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        appVersion: (state) => {
            return state.version
        },
        userId: (state) =>{
            if (state.userId === 0){
                let jwt = VueJwtDecode.decode(state.token)
                store.commit('userId', jwt.userId)
            }
           return state.userId
        }
    }
})

export default store