<template>
  <router-link to="/">Back</router-link>
  <GroupForm v-if="group" :group="group" title="Maj groupe" btn-text="update" :submit_handler="updateGroup"/>

</template>

<script>
import GroupForm from "@/components/GroupForm";
import {getGroup, updateGroup} from "@/services/api";

export default {
  name: "EditGroup",
  components:{
    GroupForm
  },
  data() {
    return {
      group: null,
    }
  },
  methods:{
    async updateGroup(group){
      let result = await updateGroup(group)

      if (result.success) {
        this.$router.push('/')
      }
    }
  },
  async mounted() {
    this.group = await getGroup(this.$route.params.groupId)
  },
}
</script>

<style scoped>

</style>