import store from '@/store.js'
import VueJwtDecode from 'vue-jwt-decode'

const API_URL = process.env.VUE_APP_API
const lang = "fr"

async function processLoginResponse(response) {
    if (response.ok) {
        let token = await response.text()

        let jwt = VueJwtDecode.decode(token)
        store.commit('admin', jwt.isAdmin)

        localStorage.setItem('token', token)
        store.commit('auth_success', token)
        return {
            success: true,
            error: false
        }
    } else {
        localStorage.removeItem('token')
        store.commit('auth_success', '')
        return {
            success: false,
            error: false
        }
    }
}

async function processResponse(response) {
    if (response.ok) {
        return await response.json()
    } else {
        if (response.status === 401) {
            localStorage.removeItem('token')
            store.commit('auth_success', '')
            window.location.href = 'login';
        } else {
            alert("Server returned " + response.status + " : " + response.statusText);
        }
    }
}

function processEmptyResponse(response) {
    if (!response.ok) {
        if (response.status === 401) {
            localStorage.removeItem('token')
            store.commit('auth_success', '')
            window.location.href = 'login';
        } else {
            alert("Server returned " + response.status + " : " + response.statusText);
        }
    }
}

export async function login(username, password) {
    let response = await fetch(API_URL + "/login", {
        "method": "POST",
        "headers": {"Content-Type": "application/json"},
        "body": JSON.stringify({"username": username, "password": password})
    })
    return await processLoginResponse(response);
}

export async function signup(username, password) {
    let response = await fetch(API_URL + "/signup", {
        "method": "POST",
        "headers": {"Content-Type": "application/json"},
        "body": JSON.stringify({"username": username, "password": password})
    })

    return await processLoginResponse(response);
}

export async function getCharacter(id) {

    let response = await fetch(API_URL + "/character/" + id, {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })
    return await processResponse(response);
}

export async function saveCharacter(id, data) {

    let response = await fetch(API_URL + "/character/" + id, {
        "method": "PUT",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(data)
    })

    return await processResponse(response);
}

export async function getCharacters() {

    let response = await fetch(API_URL + "/character", {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

function processSuccess(response) {
    if (response.ok) {
        return {
            success: true,
            error: false
        }
    } else {
        if (response.status === 401) {
            localStorage.removeItem('token')
            store.commit('auth_success', '')
            window.location.href = 'login';
        } else {
            return {
                success: false,
                error: false
            }
        }
    }
}

export async function newCharacter(name, race, c_class, gender, age, hp, strength, dexterity, constitution, intelligence, wisdom, charisma) {
    const data = {
        "name": name,
        "race": race,
        "gender": gender,
        "age": age,
        "hp": hp,
        "max_hp": hp,
        "class": c_class,
        "strength": strength,
        "dexterity": dexterity,
        "constitution": constitution,
        "intelligence": intelligence,
        "wisdom": wisdom,
        "charisma": charisma,
    }

    let response = await fetch(API_URL + "/character", {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },

        "body": JSON.stringify(data)
    })

    return processSuccess(response);
}

export async function getItems() {

    let response = await fetch(API_URL + "/item/" + lang, {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function updateItem(item) {
    let response = await fetch(API_URL + "/item/" + lang + "/" + item.id, {
        "method": "PUT",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(item)
    })

    return await processResponse(response);
}

export async function createItem(item) {
    let response = await fetch(API_URL + "/item/" + lang, {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(item)
    })

    return await processResponse(response);
}

export async function getGroups() {
    let response = await fetch(API_URL + "/group", {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function getGroup(id) {
    let response = await fetch(API_URL + "/group/"+id, {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function getGroupMember(id) {
    let response = await fetch(API_URL + "/group/"+id+"/member", {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function getGroupCharacter(groupId, characterId) {
    let response = await fetch(API_URL + "/group/"+groupId+"/character/" + characterId, {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function saveGroupCharacter(groupId, characterId, data) {

    let response = await fetch(API_URL +"/group/"+groupId+ "/character/" + characterId, {
        "method": "PUT",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(data)
    })

    return await processResponse(response);
}

export async function updateGroup(data) {
    let response = await fetch(API_URL + "/group/"+data.id, {
        "method": "PUT",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(data)
    })

    return processSuccess(response);
}

export async function popGroupItem(groupId, hash) {
    let response = await fetch(API_URL + "/group/"+groupId+"/item/" + hash, {
        "method": "DELETE",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function addGroupItem(groupId, item) {
    let response = await fetch(API_URL + "/group/"+groupId+"/item", {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(item)
    })

    return await processResponse(response);
}

export async function updateGroupItem(groupId, item) {
    let response = await fetch(API_URL + "/group/"+groupId+"/item/" + item.hash, {
        "method": "PUT",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(item)
    })

    return processEmptyResponse(response);
}

export async function getGroupItems(groupId) {
    let response = await fetch(API_URL + "/group/"+groupId+"/item", {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function addMemberToGroup(groupId, characterId){
    let response = await fetch(API_URL + "/group/"+groupId+"/member/" + characterId, {
        "method": "PUT",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return processEmptyResponse(response);
}

export async function deleteMemberFromGroup(groupId, characterId){
    let response = await fetch(API_URL + "/group/"+groupId+"/member/" + characterId, {
        "method": "DELETE",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })
    return processEmptyResponse(response);
}

export async function getUsers(){
    let response = await fetch(API_URL + "/user", {
        "method": "GET",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })

    return await processResponse(response);
}

export async function createGroup(group) {
    let response = await fetch(API_URL + "/group", {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        },
        "body": JSON.stringify(group)
    })

    return processSuccess(response);
}

export async function deleteGroup(groupId){
    let response = await fetch(API_URL + "/group/"+groupId, {
        "method": "DELETE",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })
    return processEmptyResponse(response);
}

export async function deleteCharacter(characterId){
    let response = await fetch(API_URL + "/character/"+characterId, {
        "method": "DELETE",
        "headers": {
            "Content-Type": "application/json",
            "Token": store.state.token
        }
    })
    return processEmptyResponse(response);
}