<template>
  <div class="charac-container" v-if="character">
    <Summary :character="character" :update_attributes_handler="updateAttributes"/>

    <Inventory :character="character" :items="items" :drop_item_handler="dropItem"
               :equip_item_handler="equipItem"
               :update_character_item_handler="updateCharacterItem"
               :new_item_handler="newCharacterItem"
               :money_change_handler="updateMoney"
               :send_to_group_handler="sendItemToGroup"
               :group="group"
    />

    <Equipment :equipment="equipment" :ac="character.armor_class()" :sf="character.speed_factor()"
               :remove-handler="removeEquipment" :thac0="character.thaco()"/>
  </div>
  <div class="charac-container" v-if="characterLight">
    <SummaryLight :character="characterLight" />
    <Equipment :equipment="equipment" :hide-details="true"/>
  </div>
</template>
<script>

import Inventory from './Inventory.vue'
import Summary from './Summary.vue'
import Equipment from './Equipment.vue'
import * as wasm from "csrpglib";
import SummaryLight from "@/components/SummaryLight";

let _character = null;

export default {
  name: "Character",
  components: {
    Summary,
    SummaryLight,
    Inventory,
    Equipment
  },
  data: () => ({
    character: null,
    characterLight: null,
    equipment: Array,
    items: Array,
  }),
  props: {
    characterId: Number,
    loadCharacter: {type: Function},
    saveCharacter: {type: Function},
    send_to_group_handler: {type: Function},
    group:Boolean,
  },
  methods: {
    newCharacterItem: function(item){
      wasm.add_character_item(_character, JSON.stringify(item))
      this.items = _character.get_items()
      this.save()
    },
    updateAttributes: function (attributes) {
      this.character.set_name(attributes.name)
      this.character.xp = attributes.xp
      this.character.strength = attributes.strength
      this.character.dexterity = attributes.dexterity
      this.character.constitution = attributes.constitution
      this.character.intelligence = attributes.intelligence
      this.character.wisdom = attributes.wisdom
      this.character.charisma = attributes.charisma
      this.character.hp = attributes.hp
      this.character.max_hp = attributes.max_hp
      this.character.set_avatar(attributes.avatar)
      this.save()
      this.items = _character.get_items()
    },
    updateCharacterItem(index, item) {
      wasm.update_character_item(_character, index, JSON.stringify(item))
      this.items = _character.get_items()
      this.save()
    },
    pick_items: function (item) {
      wasm.pick_items(_character, JSON.stringify(item))
      this.items = _character.get_items()
      this.save()
    },
    buy_items: function (item) {
      wasm.buy_item(_character, JSON.stringify(item));
      this.items = _character.get_items();
      this.save();
    },
    dropItem: function (index) {
      wasm.drop_item(_character, index);
      this.items = _character.get_items();
      this.save();
    },
    equipItem: function (index, body_part) {
      wasm.equip_item(this.character, index, body_part);
      this.items = _character.get_items();
      this.equipment = _character.get_equipment();
      this.save()
    },
    removeEquipment: function (bodyPart){
      wasm.unequip_item(this.character,bodyPart)
      this.items = _character.get_items();
      this.equipment = _character.get_equipment();
      this.save()
    },
    save: async function () {
      await this.saveCharacter(this.characterId,_character).catch((err) => {
        console.log(err)
      });
    },
    updateMoney: function(money){
      _character.update_money(money.platinum, money.gold, money.silver, money.copper)
      this.save()
    },
    sendItemToGroup: async function(index){
      this.send_to_group_handler(this.items[index])
      wasm.drop_item(_character, index);
      this.items = _character.get_items();
      await this.save();
    },
    reloadCharacter: async function(){
      _character = await this.loadCharacter(this.characterId);
      if (_character.light){
        this.character = null
        this.characterLight = _character
        this.equipment = _character.equipment
      }
      else{
        this.characterLight = null
        this.character = _character;
        this.items = _character.get_items();
        this.equipment = _character.get_equipment();
      }
    }
  },
  async mounted() {
    _character = await this.loadCharacter(this.characterId);

    this.character = _character;
    this.items = _character.get_items();
    this.equipment = _character.get_equipment();
  },
  watch:{
    characterId: async function(newVal){
      await this.reloadCharacter()


    }
  }
}
</script>
<style>
.charac-container {
  display: flex;
  margin: 1em;
}
</style>