<template>
  <section v-if="selected_item">
    <button class="close-btn" v-on:click="close">X</button>
    <ItemDetail :item="selected_item" :hide-details="hideDetails"/>
    <button v-if="removeHandler" v-on:click="remove">Enlever</button>
  </section>
  <section>
    <h3>Equipment</h3>
    <div class="equipments">
      <div v-if="equipment.head" class="head-equip item-case item" v-bind:class="[equipment.head.i_type]"
           v-on:click="select_item(equipment.head,bodyParts.Head)"></div>
      <div v-else class="head-equip item-case item empty"></div>
      <div v-if="equipment.right_hand" class="right-hand-equip item-case item"
           v-bind:class="[equipment.right_hand.i_type]"
           v-on:click="select_item(equipment.right_hand,bodyParts.RightHand)"></div>
      <div v-else class="right-hand-equip item-case item empty"></div>
      <div v-if="equipment.armor" class="armor-equip item-case item" v-bind:class="[equipment.armor.i_type]"
           v-on:click="select_item(equipment.armor,bodyParts.Torso)"></div>
      <div v-else class="armor-equip item-case item empty"></div>
      <div v-if="equipment.left_hand" class="left-hand-equip item-case item"
           v-bind:class="[equipment.left_hand.i_type]"
           v-on:click="select_item(equipment.left_hand,bodyParts.LeftHand)"></div>
      <div v-else class="left-hand-equip item-case item empty"></div>
      <div v-if="equipment.legs" class="legs-equip item-case item"
           v-bind:class="[equipment.legs.i_type]"
           v-on:click="select_item(equipment.legs,bodyParts.Legs)"></div>
      <div v-else class="legs-equip item-case item empty"></div>
      <div v-if="equipment.foot" class="foot-equip item-case item"
           v-bind:class="[equipment.foot.i_type]"
           v-on:click="select_item(equipment.foot,bodyParts.Foot)"></div>
      <div v-else class="foot-equip item-case item empty"></div>
    </div>
    <ul>
      <li v-if="ac">Classe d'armure: {{ ac }}</li>
      <li v-if="sf">Facteur de vitesse: {{ sf }}</li>
      <li v-if="thac0">Thac0: {{thac0}}</li>
    </ul>
  </section>
</template>
<script>

import ItemDetail from "@/components/ItemDetail";
import bodyParts from "@/const";

export default {
  name: "equipment",
  data: () => ({
    selected_item: null,
    selected_body_part: null,
    bodyParts: bodyParts,
  }),
  components: {ItemDetail},
  props: {
    equipment: {},
    ac: Number,
    sf: Number,
    thac0: Number,
    removeHandler: {type:Function},
    hideDetails:Boolean,
  },
  methods: {
    close:function (){
      this.selected_item =null
      this.selected_body_part = null
    },
    select_item: function (item, bodyPart) {
      this.selected_item = item
      this.selected_body_part = bodyPart
    },
    remove: function (){
      this.removeHandler(this.selected_body_part)
      this.selected_item = null
      this.selected_body_part = null
    }
  }
}
</script>
<style>
.equipments {
  background: url(../assets/img/human_silhouette.svg);
  background-size: 300px;
  background-position-x: -50px;
  background-repeat: no-repeat;
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.item.head-equip {
  position: absolute;
  top: 5px;
  left: 82px;
  width: 32px;
  height: 32px;
}

.item.armor-equip {
  position: absolute;
  top: 40px;
  left: 67px;
  width: 64px;
  height: 64px;
}

.item.right-hand-equip {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 64px;
  height: 64px;
}

.item.left-hand-equip {
  position: absolute;
  top: 100px;
  left: 134px;
  width: 64px;
  height: 64px;
}

.item.foot-equip {
  position: absolute;
  top: 159px;
  left: 76px;
  width: 45px;
  height: 45px;
}

.item.legs-equip {
  position: absolute;
  top: 105px;
  left: 70px;
  width: 57px;
  height: 53px;
}

</style>