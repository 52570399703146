<template>
  <div class="main-container">
    <form @submit.prevent="signup">
      <div class="box-container">
        <h2 class="heading">Créer un Compte</h2>
        <div class="form-fields">
          <input v-model="username" id="username" name="username" type="text" placeholder="Full name"/>
        </div>
        <div class="form-fields">
          <input v-model="password" id="password" name="password" type="password" placeholder="Password"/>
        </div>
        <div v-if="errorMsg" class="error-msg">
          {{ errorMsg }}
        </div>
        <div class="form-fields">
          <button class="createaccount" name="commit" type="submit">
            Créer votre compte
          </button>
        </div>
        <div class="center">
          Vous avez deja un compte?
          <router-link to="/login">Sign in</router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {signup} from "@/services/api.js";

export default {
  name: "signup",
  data() {
    return {
      username: "",
      password: "",
      errorMsg: ""
    };
  },
  methods: {
    async signup() {
      if (this.username && this.password) {
        let result = await signup(this.username, this.password).catch((err) => {
          console.log(err)
          alert("erreur de communication avec le serveur");
        });
        if (result.success) {
          this.$router.push("/");
        } else {
          this.errorMsg = "Erreur, le compte existe peut etre deja"
        }
      }
    },
  },
};
</script>
<style>
.signup-buttons {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.signup-buttons a {
  vertical-align: middle;
  text-decoration: none;
}
.signup-buttons svg {
  left: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
